.cr-input {
  &.state--faded {
    .ms-TextField-field {
      background: #eee;
    }
  }

  &.thickness--slim {
    .ms-TextField-fieldGroup {
      height: 28px;
    }
  }

  &.thickness--normal {
  }

  &.thickness--thick {
    .ms-TextField-fieldGroup {
      height: 36px;
    }
  }

  .attr--with-tooltip {
    display: flex;
    align-items: center;

    .attr--tooltip-icon {
      position: relative;
      display: inline-block;
      width: 15px;
      height: 18px;
      margin-left: 5px;
      cursor: help;

      i {
        color: $color-primary;
        font-weight: bold;
        font-size: $font-size-lg;
        text-align: center;
      }
    }
  }

  .ms-TextField-field[disabled] {
    text-overflow: clip;
  }

  .ms-TextField-errorMessage {
    color: $color-red-600;
  }

  .ms-TextField-fieldGroup {
    border-color: #ccc;
    border-radius: 0;

    &:hover {
      border-color: #aaa;
    }
  }

  .ms-Label {
    font-size: $font-size-sm;
  }

  .ms-TextField-description {
    display: block;
    padding-top: 0.3rem;
  }

  &.is-active {
    .ms-TextField-fieldGroup {
      border-color: $color-primary;
      outline: none;
    }

    .ms-TextField-fieldGroup {
      &:after {
        display: none;
      }
    }
  }

  &.light-disabled {
    .ms-TextField-fieldGroup {
      > div,
      > input {
        background: #f7f7f7;
      }
    }
  }

  &.resize-disabled {
    .ms-TextField-field {
      resize: none;
    }
  }

  &.state--valid {
    .ms-TextField-fieldGroup {
      border-color: $app-color-success;
    }

    i[data-icon-name] {
      color: $app-color-success;
      font-weight: bold;
    }
  }

  &.state--invalid {
    .ms-TextField-fieldGroup {
      border-color: $app-color-danger;
    }

    i[data-icon-name] {
      color: $app-color-danger;
      font-weight: bold;
      font-size: $font-size-sm;
    }
  }

  &.state--warning {
    .ms-TextField-fieldGroup {
      border-color: $app-color-warning;
    }

    i[data-icon-name] {
      color: $app-color-warning;
      font-weight: bold;
      font-size: $font-size-sm;
    }
  }
}

.cr-file-upload-input-container {
  &.--removable {
    margin-right: 2rem;
    margin-top: 1rem;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    align-items: flex-end;

    .cr-file-upload-input {
      width: 100%;
    }
  }

  .cr-file-upload-input {
    .ms-TextField-fieldGroup,
    .ms-TextField-fieldGroup input {
      cursor: pointer;
      user-select: none;
    }

    .ms-TextField-fieldGroup {
      position: relative;

      &:after,
      &:before {
        content: "";
        position: absolute;
        top: 50% !important;
        left: auto !important;
        right: 4px !important;
        box-sizing: border-box;
        width: 20px !important;
        height: 20px !important;
        margin-top: -10px !important;
        border: 3px solid #259ac5;
        border-right-color: transparent;
        border-bottom-color: transparent;
        border-radius: 50%;
        animation: rotateOpacity 0.95s ease-in-out infinite 0.1s;
        transition: all ease-in-out 0.4s;
        stroke-linecap: round;
        z-index: 10000;
        opacity: 0;
        display: block !important;
      }

      &:after {
        border-color: #36393f;
        border-right-color: transparent;
        border-bottom-color: transparent;
        animation-delay: 0.25s;
        content: "done" !important;
        color: rgba($color-primary, 0);
        font-family: "Material Icons Outlined";
        text-align: center;
        font-size: $font-size-lg;
        line-height: 15px;
        font-weight: bold;
      }
    }

    &.status--done,
    &.status--error,
    &.status--uploading {
      .ms-TextField-fieldGroup {
        &:after,
        &:before {
          opacity: 1;
        }
      }
    }

    &.status--done,
    &.status--error {
      .ms-TextField-fieldGroup {
        &:after,
        &:before {
          animation-iteration-count: 1;
          border-color: rgba(#fff, 0);
        }

        &:after {
          content: "done";
          color: $color-primary;
        }
      }
    }

    &.status--done .ms-TextField-fieldGroup:after {
      content: "done" !important;
      color: $color-primary;
    }

    &.status--error .ms-TextField-fieldGroup:after {
      content: "close" !important;
      color: $app-color-danger;
    }
  }
}

.cr-input--image-upload {
  .cr-input {
    @keyframes rotateOpacity {
      0% {
        transform: rotate(0deg);
      }

      100% {
        transform: rotate(1turn);
      }
    }

    cursor: pointer;

    &:before {
      content: "insert_photo";
      color: #eee;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 150px;
      text-align: center;
      font-size: 5rem;
      font-family: "Material Icons Outlined";
      line-height: 150px;
      cursor: pointer;
    }

    .ms-TextField-fieldGroup {
      height: 150px;
      background: transparent;
      border-width: 2px;
      border-color: #eee;
      cursor: pointer;

      input {
        cursor: pointer;
      }
    }

    &:hover {
      &:before {
        color: #e3e3e3;
      }

      .ms-TextField-fieldGroup {
        &:hover {
          border-color: #e3e3e3;
        }
      }
    }

    &.is-active {
      &:before {
        color: #dfdfdf;
      }

      .ms-TextField-fieldGroup {
        border-color: #dfdfdf;
      }
    }

    input {
      color: transparent;
    }

    .ms-TextField-wrapper {
      &:after,
      &:before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        box-sizing: border-box;
        width: 25px;
        height: 25px;
        margin-top: -12.5px;
        margin-left: -12.5px;
        border: 5px solid #259ac5;
        border-right-color: transparent;
        border-bottom-color: transparent;
        border-radius: 50%;
        animation: rotateOpacity 0.95s ease-in-out infinite 0.1s;
        stroke-linecap: round;
        z-index: 10000;
        opacity: 0;
      }

      &:after {
        border-color: #36393f;
        border-right-color: transparent;
        border-bottom-color: transparent;
        animation-delay: 0.25s;
      }
    }

    &.status--uploading {
      .ms-TextField-wrapper {
        &:after,
        &:before {
          opacity: 1;
        }
      }
    }
  }
}

.cr-input--img-preview {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;

  img {
    max-width: 100%;
    max-height: 100%;
  }

  &.status--uploaded {
    display: block;
  }
}

.cr-callout-input {
  display: flex;

  > div {
    position: relative;
    width: 100%;
  }
}

.cr-callout-input-callout {
  .ms-Callout-main {
    > .cr-section {
      margin: 0;
    }
  }
}
