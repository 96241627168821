.cr-table {
  &.with--row-link {
    .ms-List-cell {
      position: relative;

      .cr-table--row-link {
        text-decoration: none;
        text-indent: -9999px;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: block;

        &.with--misc {
          right: 60px;
        }
      }

      a,
      .type--title-lg &:not(.--plain-link) {
        text-decoration: underline;
      }

      &:hover {
        .ms-DetailsRow {
          background: rgb(243, 242, 241);
        }

        a,
        .type--title-lg {
          text-decoration: underline;
          color: $app-link-color;
        }
      }
    }
  }

  .ms-DetailsHeader {
    padding-top: 0;

    .ms-DetailsHeader-cell {
      line-height: normal;
      text-overflow: clip;
      white-space: normal;
      word-break: keep-all;

      .ms-DetailsHeader-cellTitle {
        align-items: center;
        height: 100%;

        .ms-DetailsHeader-cellName {
          text-align: center;
        }
      }

      &:hover {
        background: transparent;
      }
    }

    .cr-table-header-column {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      display: block;
      inset: 0px;

      .cr-table-header-column-title {
        display: flex;
        align-items: center;
        height: 100%;
        padding: 0 12px;

        &.--align-center {
          justify-content: center;
        }
      }

      .cr-table-header-column-label {
        font-weight: bold;
        font-size: $font-size-base;
        word-break: break-all;
      }

      .sort-icon {
        position: absolute;
        right: 12px;
      }

      .cr-table-header-column-filter {
        width: 26px;
        height: 26px;
        margin-left: 4px;
        color: #ccc;

        .ms-Icon {
          margin: 0;
          font-size: $font-size-base;
        }
      }

      &.--filtering,
      .cr-table-header-column-filter {
        &:hover {
          color: #222;
        }
      }

      &.--filtered {
        .cr-table-header-column-filter {
          color: $color-primary;

          .ms-Icon {
            font-weight: bold;
          }
        }
      }

      &.--sortable {
        cursor: pointer;

        .cr-table-header-column-sort-up {
          display: none;

          &.--sorting {
            display: initial;
          }
        }

        &:hover {
          background-color: #f3f2f1;

          .cr-table-header-column-sort-up {
            display: initial;
          }
        }

        &:active {
          background-color: #edebe9;
        }

        [class^="cr-table-header-column-sort"],
        [class*="cr-table-header-column-sort"] {
          width: 13px;
          height: 13px;
          color: #ccc;

          .ms-Icon {
            margin: 0;
            font-size: $font-size-xs;
          }
        }
      }
    }
  }

  .ms-GroupedList-group {
    .ms-GroupHeader {
      background: #f5f5f5;

      .ms-GroupHeader-title {
        font-size: 13px;
      }
    }
  }

  .ms-DetailsRow {
    &.root-141:hover {
      .cr-icon-button {
        button {
          &.is-disabled {
            background-color: #f3f2f1;
          }
        }
      }
    }

    .--block-link {
      display: flex;
      align-items: center;
      width: 100%;
      height: 100%;
      padding: 20px 0;
      margin: -20px 0;
      text-decoration: none;

      &:not(.--plain-link) {
        color: $app-link-color;

        &.cr-col {
          text-decoration: underline;
        }
      }

      &:hover {
        color: $color-primary-dark;
      }

      .cr-col--icon {
        margin-right: 5px;
      }

      span {
        text-decoration: underline;
      }
    }

    .--plain-link {
      color: $app-text-color;
      text-decoration: none;

      &:hover {
        color: $color-primary-dark;
      }

      &.--block-link {
        span {
          text-decoration: none;
        }
      }

      &:hover {
        &:not(.--block-link) {
          text-decoration: underline;
        }

        &.--block-link {
          span {
            text-decoration: underline;
          }
        }
      }
    }

    .--shimmer {
      @keyframes shimmer {
        0% {
          background-position: -1000px 0;
        }
        100% {
          background-position: 1000px 0;
        }
      }

      width: 100%;
      height: 100%;

      animation: shimmer 2s infinite linear;
      background: linear-gradient(to right, #eff1f3 4%, #e2e2e2 25%, #eff1f3 36%);
      background-size: 1000px 100%;
    }

    &.--faded {
      div {
        color: #b0b0b0;
      }

      a {
        color: lighten($color-primary-light, 25%);
      }
    }

    &.--info {
      background: $color-green-600;
    }

    &.--succ {
      background: $color-blue-100;
    }

    &.--warn {
      background: $color-yellow-200;
    }

    &.--danger {
      background: lighten($color-red-200, 3%);
    }

    .cr-col {
      align-items: center;
      flex-basis: 100%;

      &.--block {
        width: 100%;
      }

      &.--ellipsis {
        overflow: hidden;
        text-overflow: ellipsis;
      }

      &.--verbose {
        white-space: normal;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
      }

      &.--faded {
        color: #999;
      }

      &.--info {
        color: $color-green-600;
      }

      &.--succ {
        color: $color-blue-700;
      }

      &.--warn {
        color: $color-yellow-600;
      }

      &.--danger {
        color: $color-red-600;
      }

      &.--severe,
      &.--critical {
        display: flex;

        .--wrapped,
        .cr-col--icon {
          color: #fff;
          z-index: 1;
        }

        &:before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
        }
      }

      &.--severe {
        &:before {
          background: $color-yellow-600;
        }
      }

      &.--critical {
        &:before {
          background: $color-red-600;
        }
      }

      &.--plain-link {
        a {
          color: $app-text-color;
        }
      }

      &.type--menu {
        margin-top: -11px;
        margin-bottom: -11px;
      }

      &.type--icon {
        display: flex;
        flex-basis: 100%;
        justify-content: center;
      }

      &.type--wf-icon {
        .--fluent {
          font-size: 1.25em;
        }
      }

      .--wrapped {
        position: relative;
      }

      .cr-col--icon {
        vertical-align: middle;
        margin-right: 5px;
      }

      a {
        color: $color-primary;
      }

      .selector {
        margin-top: 3px;
        margin-left: -3px;
        padding: 0;
        list-style: none;

        li {
          display: inline-block;
          margin: 2px;

          a {
            padding: 2px 4px;
            border: 0;
            border-radius: 5px;
            background-color: $color-primary;
            color: #fff;
            text-decoration: none;
            white-space: nowrap;

            &:hover {
              background-color: darken($color-primary, 10%);
            }
          }

          &:first-child {
            margin-left: 0;
          }
        }
      }
    }

    &.--align-center,
    .--align-center {
      display: flex;
      flex-basis: 100%;
      justify-content: center;
    }

    &.--align-right,
    .--align-right {
      display: flex;
      flex-basis: 100%;
      justify-content: center;
    }

    .--empty {
      opacity: 0.45;
    }

    .--sub {
      display: inline-block;
      margin-left: 4px;
      padding: 2px 0;
      color: $color-primary;
      font-weight: bold;
      font-size: 11px;
    }

    .--block {
      display: block;
      margin: 0;
      padding: 0;
      color: reset;
      font-weight: bold;
      font-style: normal;
      font-size: 11px;
    }

    .--badge {
      display: inline-block;
      vertical-align: baseline;
      height: 14px;
      margin-left: 5px;
      padding: 0 4px;
      border-radius: 10px;
      background-color: $app-color-warning;
      color: #fff;
      font-size: 9px;
      line-height: 14px;
    }
  }

  .--bold {
    font-weight: bold;
  }

  .--italic {
    font-style: italic;
  }

  .--blink {
    @keyframes blink {
      30% {
        opacity: 1;
      }
      80% {
        opacity: 0;
      }
    }

    animation: blink 1.1s linear infinite;
  }

  .--strike {
    text-decoration: line-through;
    text-decoration-color: #999 !important;
  }

  .ms-DetailsRow-cell {
    display: flex;
    align-items: center;
  }

  .ms-DetailsHeader-check .ms-Check-circle,
  .ms-DetailsRow-cellCheck .ms-Check-circle {
    color: #ccc;
  }

  .ms-DetailsHeader-check .ms-Check.is-checked .ms-Check-circle,
  .ms-DetailsRow-cellCheck .ms-Check.is-checked .ms-Check-circle {
    color: $color-primary;
  }

  .ms-DetailsHeader-check .ms-Check.is-checked:before,
  .ms-DetailsRow-cellCheck .ms-Check.is-checked:before {
    background: $color-primary;
  }

  .cr-table-wrapped.--empty {
    overflow: hidden !important;
  }

  .cr-table-empty {
    padding: calc(10vh) 0;
  }

  .cr-table-selected-rows {
    display: flex;
    margin-right: 1rem;
    justify-content: flex-end;
    align-items: center;

    span {
      font-weight: bold;
    }

    .ms-Button--icon {
      margin-left: 15px;
    }
  }

  .cr-table-search {
    display: flex;
    margin-bottom: 1.5rem;

    .spacer {
      background: #ccc;
      width: 1px;
      height: 15px;
      margin: 0 12px;
    }

    > div,
    .left > div,
    .right > div {
      display: flex;
      align-items: center;
      line-height: 32px;
    }

    .rpp .cr-dropdown {
      width: 65px;
    }

    .left {
      flex: 1;

      .cr-table-search-stacks {
        padding-left: 0;
        text-align: left;
      }
    }

    .right {
      flex: 1;
      justify-content: flex-end;

      .cr-table-search-stacks {
        text-align: right;
      }
    }

    .cr-table-search-stacks {
      margin: 0;
      list-style: none;

      .cr-table-search-stack {
        display: inline-block;
        margin: 2px;

        .ms-Button {
          overflow: hidden;
          max-width: 310px;
          height: 28px;
          padding: 0 0 0 6px;
          border-radius: 0.9rem;
          font-size: $font-size-sm;
          text-overflow: ellipsis;
          white-space: nowrap;

          .ms-Button-label {
            &:after {
              content: "clear";
              position: relative;
              vertical-align: middle;
              margin-left: 6px;
              padding: 3px;
              border-radius: 50%;
              background: #f0f0f0;
              font-family: "Material Icons Outlined";
            }
          }
        }
      }
    }

    .cr-dropdown {
      flex-grow: 0;
      width: 140px;
      margin-right: 5px;
      margin-left: 5px;

      &:first-child {
        margin-left: 0;
      }

      &.--search {
        flex-grow: 1;
        min-width: 70px;
        max-width: 250px;
      }

      .ms-Dropdown {
        width: 100%;
      }
    }

    .cr-input {
      flex-grow: 1;
      min-width: 70px;
      max-width: 250px;
      margin-right: 5px;

      .ms-TextField-wrapper {
        width: 100%;
      }
    }

    &.rtl {
      flex-direction: row-reverse;

      .left {
        justify-content: flex-end;

        .option {
          display: flex;
          align-items: center;
        }
      }

      .right {
        justify-content: flex-start;

        .cr-table-search-stacks {
          padding-left: 0;
          text-align: left;
        }
      }
    }
  }

  .cr-icon-button:not(.primary):not(.warning) {
    button {
      &.is-disabled {
        background-color: white;
      }
    }
  }

  div {
    color: $app-text-color;
  }

  .cr-table-child-footer {
    display: flex;
  }

  .cr-table--sticky-scrollbar {
    display: none;
    overflow: hidden;

    > div {
      width: 100%;
      height: 100%;
      position: relative;

      > span {
        cursor: pointer;
        position: absolute;
        top: 0;
        left: 0;
        background: #cfcfcf;
        border-radius: 6px;
        bottom: 0;
      }
    }
  }

  &.attr--sticky-scrollbar {
    .ms-DetailsList {
      &::-webkit-scrollbar {
        display: none;
        width: 0;
        height: 0;
      }

      &::-webkit-scrollbar-track {
        background-color: #fff;
        display: none;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #fff;
        display: none;
      }
    }

    .cr-table--sticky-scrollbar {
      display: block;
      width: 100%;
      height: 8px;
      position: sticky;
      bottom: 60px;
      background: #fff;

      &.position--bottom {
        bottom: 0px;
      }
    }
  }
}

.type--inner-crf-stage {
  $-size: 30px;
  $-size-sm: 20px;
  $-border: 4px;
  $-safe: 5px;
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  box-sizing: border-box;
  width: $-size;
  height: $-size;
  margin-top: calc(#{$-size} / -2);
  margin-left: calc(#{$-size} / -2);
  border: solid $-border #999;
  border-radius: 50%;

  .--query {
    $-query-size: calc(#{$-size} - (#{$-border} * 2));

    position: absolute;
    top: 50%;
    left: 50%;
    width: $-query-size;
    height: $-query-size;
    margin-top: calc(#{$-query-size} / -2);
    margin-left: calc(#{$-query-size} / -2);
    border-radius: 50%;
    font-weight: bold;
    font-size: 11px;
    line-height: $-query-size;
    text-align: center;
    pointer-events: none;

    &.--resolving {
      background-color: yellow;
      color: #000;
    }

    &.--open {
      background-color: #ed214b;
      color: #fff;
    }

    &.--resolved {
      position: absolute;
      top: -7px;
      left: 22px;
      width: 7px;
      height: 7px;
      background-color: #999;
    }
  }

  .--visit {
    pointer-events: none;
    position: absolute;
    top: calc((#{$-border} * -1) + #{$-safe});
    left: calc((#{$-border} * -1) + #{$-safe});
    box-sizing: border-box;
    width: $-size;
    height: $-size;
    border: solid $-border #aaa;
    border-radius: 50%;

    &.--na {
      position: absolute;
      top: calc((#{$-border} * -1) + #{$-safe});
      left: calc((#{$-border} * -1) + #{$-safe});
      box-sizing: border-box;
      width: $-size;
      height: $-size;
      border: solid $-border #dfdfdf;
      border-radius: 50%;
      background: repeating-linear-gradient(-45deg, transparent, transparent 3px, #dfdfdf 3px, #dfdfdf 6px);
    }

    &.--save {
      position: absolute;
      top: 50%;
      left: 50%;
      box-sizing: border-box;
      width: $-size;
      height: $-size;
      margin-top: calc(#{$-size} / -2);
      margin-left: calc(#{$-size} / -2);
      border: solid calc(#{$-border} * 1.8) transparent;
      border-color: #999 !important;
      border-radius: 50%;
    }
  }

  .--esign,
  .--sdv,
  .--freezing,
  .--locking {
    position: absolute;
    top: 50%;
    left: 50%;
    box-sizing: border-box;
    width: $-size;
    height: $-size;
    margin-top: calc(#{$-size} / -2);
    margin-left: calc(#{$-size} / -2);
    border: solid calc(#{$-border} * 1.8) transparent;
    border-radius: 50%;
    pointer-events: none;
  }

  .--sdv {
    border-left-color: $color-sdv !important;
  }
  .--freezing {
    border-top-color: $color-freeze !important;
  }
  .--esign {
    border-right-color: $color-sign !important;
  }
  .--locking {
    border-bottom-color: $color-lock !important;
  }

  .--region {
    position: absolute;
    top: calc(#{$-safe} * -1);
    right: calc(#{$-safe} * -1);
    bottom: calc(#{$-safe} * -1);
    left: calc(#{$-safe} * -1);
    z-index: 2;
    background: transparent;
  }

  .type--crf-stage-summary {
    display: none;
  }

  &.--small {
    width: $-size-sm;
    height: $-size-sm;
    margin-top: calc(#{$-size-sm} / -2);
    margin-left: calc(#{$-size-sm} / -2);

    .--save,
    .--esign,
    .--sdv,
    .--freezing,
    .--locking {
      width: $-size-sm;
      height: $-size-sm;
      margin-top: calc(#{$-size-sm} / -2);
      margin-left: calc(#{$-size-sm} / -2);
      border: solid calc(#{$-border} * 1.2) transparent;

      &.--save {
        border-width: calc(#{$-border} * 1.2);
      }
    }
  }
}

.bordered-wrap {
  border: 1px solid #ccc;
}

.padded-wrap {
  padding: 0 10px 10px 10px;
}

.type--crf-stage-summary {
  position: fixed;
  z-index: 900000000;
  min-width: 4rem;
  padding: 0.65rem 0.95rem;
  background: #fefefe;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.15);

  &:before {
    content: "";
    position: absolute;
    top: -3px;
    left: -3px;
    width: 10px;
    height: 10px;
    background: #fff;
    box-shadow: 0 0 12px rgba(0, 0, 0, 0.15);
  }

  table {
    width: 100%;
  }

  td {
    padding-right: 0;
    padding-bottom: 0.35rem;
  }

  tr {
    &:last-child {
      td {
        padding-bottom: 0;
      }
    }
  }

  .head {
    padding-right: 0.7rem;
    font-weight: bold;

    &.--save:before,
    &.--sdv:before,
    &.--freezing:before,
    &.--esign:before,
    &.--locking:before {
      content: "";
      position: relative;
      display: inline-block;
      width: 10px;
      height: 10px;
      margin-right: 7px;
    }

    &.--save:before {
      background-color: #333c40;
    }
    &.--sdv:before {
      background-color: $color-sdv;
    }
    &.--freezing:before {
      background-color: $color-freeze;
    }
    &.--esign:before {
      background-color: $color-sign;
    }
    &.--locking:before {
      background-color: $color-lock;
    }
  }

  hr {
    height: 1px;
    margin: 0.5rem 0;
    padding: 0;
    background: #ddd;
  }

  .cont {
    text-align: right;

    &.done {
      font-weight: bold;
    }

    &.yet {
      color: #888;
    }
  }
}

@media (max-width: 700px) {
  .cr-table {
    .cr-table-search {
      .rpp .label {
        display: none;
      }
    }

    .right {
      .cr-dropdown {
        max-width: 80px;

        &.--search {
          max-width: 90px;
        }
      }
    }
  }
}

@media (max-width: 450px) {
  .cr-table {
    .cr-table-search {
      .left {
        display: none;
      }

      .right {
        .cr-dropdown {
          max-width: 80px;

          &.--search {
            max-width: 250px;
          }
        }
      }
    }
  }
}

@media (max-width: 300px) {
  .cr-table {
    .cr-table-search {
      .rpp .cr-dropdown {
        width: auto;
      }

      .cr-dropdown {
        width: auto;
      }
    }
  }
}

.cr-table-header-search-callout {
  padding: 0.5rem;

  .--type-text {
    display: flex;
    width: 270px;
    max-width: 100%;

    .--type-text-input-wrapper {
      display: flex;
      align-items: center;
      position: relative;

      .cr-input {
        flex-basis: 100%;

        &.is-active {
          .ms-TextField-fieldGroup {
            outline: initial;
          }
        }

        input {
          padding: 0px 25px 0 8px;
        }
      }

      .cr-icon-button {
        position: absolute;
        right: 0;

        .ms-Button--icon {
          margin: 0;

          &:hover {
            background-color: initial;
          }

          .ms-Icon {
            margin: 0;
          }
        }
      }
    }

    .cr-button {
      min-width: 60px;
      margin-left: 2px;
      padding: 0;
    }
  }

  .--type-filterable-check {
    width: 200px;
    max-width: 100%;

    .--type-text {
      margin-bottom: 0.5rem;

      .cr-button {
        margin-top: 0;
        width: initial;
      }
    }

    .cr-checkboxes {
      overflow-y: auto;
      height: 150px;
    }

    .ms-Button {
      width: 100%;
      margin-top: 10px;
    }
  }

  .--type-check {
    width: 200px;
    max-width: 100%;

    .cr-checkboxes {
      overflow-y: auto;
      max-height: 150px;
    }

    .ms-Button {
      width: 100%;
      margin-top: 10px;
    }
  }

  .--type-range {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 400px;
    max-width: 100%;

    .--delimiter {
      margin-right: 0.5rem;
      margin-left: 0.5rem;
      font-size: $font-size-xl;
    }

    .cr-input {
      flex-basis: 100%;
    }

    .cr-button {
      min-width: 60px;
      margin-left: 2px;
      padding: 0;
    }
  }
}
