.hb-radio {
  $hb-radio-item-spacing: 0.4rem;
  $hb-radio-item-padding: 0.8rem;

  .hb-radio-items {
    margin: calc(#{$hb-radio-item-spacing} * -1);

    .hb-radio-item {
      position: relative;
      display: flex;
      align-items: center;
      margin: $hb-radio-item-spacing;
      padding: $hb-radio-item-padding;
      border-radius: 10px;
      background: #fff;
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.03);
      font-size: $font-size-lg;
      line-height: 135%;
      cursor: pointer;
      user-select: none;

      &:hover {
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.13);

        &.--disabled {
          box-shadow: 0 0 20px rgba(0, 0, 0, 0.03);
        }
      }

      &:before {
        content: "";
        display: inline-block;
        width: 12px;
        min-width: 12px;
        height: 12px;
        margin-top: 1px;
        margin-right: 10px;
        border: 2px solid $color-gray-400;
        border-radius: 50%;
      }

      .cr-icon {
        color: $color-gray-500;
      }

      &.--selected {
        color: $color-primary;
        font-weight: bold;

        &:before {
          border-color: $color-primary;
        }

        &:after {
          content: "";
          position: absolute;
          left: calc(#{$hb-radio-item-padding} + 4px);
          top: calc(#{$hb-radio-item-padding} + 5px);
          width: 8px;
          height: 8px;
          border-radius: 50%;
          background: $color-primary;
        }

        .cr-icon {
          color: $color-primary;
        }
      }

      &.--disabled {
        background: #f0f0f0;
      }
    }
  }

  &.--direction-vertical {
  }

  &.--direction-horizontal {
    .hb-radio-items {
      display: flex;

      .hb-radio-item {
        display: flex;
        flex-basis: 100%;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .hb-radio-item-icon-and-text-container {
          text-align: center;
        }

        .hb-radio-item-icon {
          display: inline-block;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 50px;
          height: 50px;
          margin-bottom: 10px;
          font-size: $font-size-5xl;
        }
        .hb-radio-item-text {
          &.font--small {
            font-size: $font-size-base;
          }
          &.font--medium {
            font-size: $font-size-xl;
          }
          &.font--large {
            font-size: $font-size-3xl;
          }
        }
      }
    }
  }

  &.--appearance-bullet {
    .hb-radio-item {
      position: relative;
      align-items: flex-start;

      &:hover {
        &:before {
          border-color: $color-gray-500;
        }

        &.--disabled:before {
          border-color: $color-gray-400;
        }

        &.--disabled.--selected:before {
          border-color: $color-primary;
        }
      }
    }

    &.--direction-vertical {
      .hb-radio-item {
        display: flex;

        .hb-text {
          margin-top: 3px;
        }

        .hb-radio-item-icon-and-text-container {
          .hb-radio-item-text {
            &.font--small {
              font-size: $font-size-lg;
            }
            &.font--medium {
              font-size: $font-size-3xl;
            }
            &.font--large {
              font-size: $font-size-5xl;
            }
          }
        }
      }
    }
  }

  &.--appearance-bordered {
    .hb-radio-item {
      border: 2px solid $color-gray-400;

      &:before,
      &:after {
        display: none;
      }

      &:hover {
        border: 2px solid $color-gray-500;

        &.--disabled {
          border: 2px solid $color-gray-400;
        }

        &.--disabled.--selected {
          border: 2px solid $color-primary;
        }
      }

      &.--selected {
        border-color: $color-primary;
      }
    }

    &.--direction-vertical {
      .hb-radio-item {
        display: block;
      }

      .hb-radio-item-icon-and-text-container {
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        align-items: center;
      }

      .hb-radio-item-icon {
        display: inline-block;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50px;
        height: 50px;
        margin-bottom: 5px;
        margin-top: 5px;
        font-size: $font-size-5xl;
      }
    }
  }
}
