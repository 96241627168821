@import "variables.scss";

.maven-docs {
  .cr-page-title {
    position: relative;
    z-index: 1;
  }
}

.mdx--pdf-viewer-zoom {
  display: flex;
}

.app-docs-document-view {
  min-height: 300px;

  &.content-type--text {
    .app-docs-document-view-inner {
      width: 100%;
      max-width: 840px;
      min-height: calc(100vh - #{$app-content-padding} - #{$app-header-height} - #{$app-footer-bar-height} - 12rem);
      padding: calc(#{$app-content-box-padding} * 1.2);
      background-color: $app-content-box-background;
      box-shadow: $app-content-box-shadow;
    }
  }

  > .cr-section-inner {
    padding: 0;
    background: transparent;
    box-shadow: none;
  }

  &.content-type--pdf,
  &.content-type--docs_pdf {
    > .cr-section-inner {
      padding: 0;
      background: transparent;
      box-shadow: none;

      > div {
        > .cr-section-inner-content {
          position: relative;
          display: flex;
          justify-content: flex-start;

          @media (max-width: $break-xl) {
            display: block;
          }
        }
      }
    }
  }

  .mdx--pdf-viewer {
    span {
      cursor: default;
    }
  }

  .app-docs-document-view-inner {
    overflow-y: auto;
    width: 100%;
    margin: auto;
  }

  .rpv-core__doc-error {
    .rpv-core__doc-error-text {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      min-height: 32vh;
      background: transparent;
      color: $app-text-color;
      font-weight: bold;

      &:before {
        content: "question_mark";
        color: $app-color-danger;
        font-size: 3rem;
        font-family: "Material Icons Outlined";
        text-indent: 0;
      }

      &:after {
        content: "Failed to load a PDF file. Please try again later.";
        margin-top: 10px;
        font-weight: normal;
        text-align: center;
        text-indent: 0;
      }
    }
  }

  .rpv-core__inner-page {
    background: transparent;
    user-select: none;
  }

  .rpv-core__viewer {
    height: auto !important;

    > div {
      height: auto !important;

      .rpv-core__inner-pages {
        height: auto !important;

        > div {
          height: auto !important;
        }

        .rpv-core__inner-page {
          position: relative !important;
          top: unset !important;
          left: unset !important;
          transform: unset !important;

          .rpv-core__page-layer {
            transform-origin: top left;
          }
        }
      }
    }
  }

  .rpv-core__spinner {
    position: relative;
    position: relative;
    box-sizing: border-box;
    width: 34px !important;
    height: 34px !important;
    border: 3px solid #ccc;
    border-collapse: collapse;
    border-radius: 50%;
    pointer-events: none;
    transition: opacity ease-in-out 0.2s;
    animation: none;

    &:after,
    &:before {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      box-sizing: border-box;
      width: 34px;
      height: 34px;
      margin-top: -17px;
      margin-left: -17px;
      border: 3px solid #259ac5;
      border-right-color: transparent;
      border-bottom-color: transparent;
      border-radius: 50%;
      animation: rotateOpacity 0.95s ease-in-out infinite 0.1s;
      stroke-linecap: round;
    }

    &:after {
      border-color: #36393f;
      border-right-color: transparent;
      border-bottom-color: transparent;
      animation-delay: 0.25s;
    }
  }

  .rpv-core__page-layer {
    background: #fff;

    &:after {
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      border: 3px solid $app-content-background;
      box-shadow: none;
    }
  }

  .rpv-core__doc-loading {
    background: transparent;
  }

  .app-docs-document-esign {
    position: sticky;
    top: calc(#{$app-content-padding} + #{$app-header-height});
    flex-shrink: 0;
    overflow-x: hidden;
    overflow-y: auto;
    max-width: 400px;
    max-height: calc(100vh - #{$app-content-padding} - #{$app-header-height} - #{$app-footer-bar-height} - 0.35rem);
    padding-left: 20px;
    transition: all ease-in-out 0.25s;

    @media (max-width: $break-xl) {
      position: absolute;
      top: 0;
      right: 0;

      .app-docs-document-esign-inner {
        border: 1px solid #ccc;
      }
    }

    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
    }

    &::-webkit-scrollbar-track {
      background-color: #e4e4e4;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #999;
    }

    .app-docs-document-esign-inner {
      width: 350px;
      margin-top: 1rem;
      background-color: $app-content-box-background;
      box-shadow: $app-content-box-shadow;

      .app-docs-document-esign--container {
        padding: calc(#{$app-content-box-padding} * 1.2);

        h1 {
          &:before {
            content: "";
            position: absolute;
            top: 0;
            z-index: 4;
            width: 100%;
            height: 3px;
            margin-left: calc(#{$app-content-box-padding} * -1.2);
            padding-right: calc(#{$app-content-box-padding} * 1.2);
            background: $color-primary;
          }
        }

        &.mode--advanced {
          h1 {
            &:before {
              background: $app-color-danger;
            }
          }
        }

        &.mode--signing {
          h1 {
            &:before {
              display: none;
            }
          }
        }
      }

      &:first-child {
        margin-top: 0;
      }

      h1 {
        position: sticky;
        top: 0;
        z-index: 3;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: -1.31rem -15px 1.5rem -15px;
        padding: 1.31rem 15px 1rem 15px;
        border-bottom: 1px solid #eee;
        background: #fff;
        background: #fff;
        user-select: none;

        &.app-docs-document-esign--create-sign {
          margin-bottom: 0.5rem;
          border-bottom: none;
        }

        span {
          display: flex;
          align-items: center;
          margin-top: -3px;
          font-size: $font-size-2xl;
          line-height: 100%;
        }

        button {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 30px;
          height: 30px;
          border: none;
          border-radius: 2px;
          background: transparent;
          color: #000;
          font-size: $font-size-xl;
          cursor: pointer;
          transition: all ease-in-out 0.15s;

          &:hover {
            background: #f3f3f3;
            color: #333;
          }
        }
      }

      .app-docs-document-esign--signature-canvas-container {
        position: relative;
        max-width: 300px;
        margin: auto;

        &:before {
          content: "(SIGN HERE)";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          background: #eee;
          color: #aaa;
          line-height: 150px;
          letter-spacing: 5px;
          text-align: center;
          text-indent: 0;
        }

        .app-docs-document-esign--signature-canvas-clear {
          position: absolute;
          top: 0;
          right: 0;
          z-index: 2;

          button {
            position: relative;
            z-index: 2;
            margin: 0;
            border: 1px solid #333;
            background: #eee;
            color: #000;
            outline: none;
            cursor: pointer;
            user-select: none;

            &:hover {
              background: #e0e0e0;
            }
          }
        }

        .app-docs-document-esign--signature-canvas {
          position: relative;
        }
      }

      .app-docs-document-esign--buttons {
        display: flex;
        flex-direction: column;
        margin-top: 15px;

        button {
          display: block;
          margin-top: 5px;

          &:not(:disabled) {
            .ms-Button-label,
            .ms-Button-textContainer,
            .ms-Button-flexContainer {
              cursor: pointer;
            }
          }
        }
      }

      .app-docs-document-esign--attrs {
        margin-top: -0.5rem;

        &:after {
          content: "";
          display: block;
          width: 100%;
          height: 1px;
          margin: 1rem 0;
          background: #e6e6e6;
        }
      }

      .app-docs-document-esign--advanced-mode-check {
        background: rgba($app-color-warning, 0.1);

        .cr-checkbox {
          width: 100%;

          span {
            font-weight: bold;
          }
        }
      }

      .app-docs-document-esign--singers {
        .app-docs-document-esign--singer {
          padding-top: 15px;
          padding-bottom: 15px;
          border-bottom: 1px solid #eee;

          &:first-child {
            padding-top: 0;
          }

          .app-docs-document-esign--title {
            display: flex;
            align-items: center;
            justify-content: space-between;

            button {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 30px;
              height: 30px;
              border: none;
              border-radius: 2px;
              background: transparent;
              color: #000;
              font-size: $font-size-xl;
              transition: all ease-in-out 0.15s;

              &:disabled {
                color: #bbb;
              }

              &:hover:not(:disabled) {
                background: #f3f3f3;
                color: #333;
                cursor: pointer;
              }
            }

            em {
              margin-left: 3px;
              padding: 2px 8px;
              border-radius: 10px;
              background: #efefef;
              font-style: normal;

              &.status--none {
              }

              &.status--wait {
              }

              &.status--delivering,
              &.status--delivering-skipped,
              &.status--delivered {
                background: $app-color-success;
                color: $color-primary-text;
              }

              &.status--delivering-failed {
                background: $app-color-warning;
                color: $color-primary-text;
              }

              &.status--opened {
                background: $app-color-warning;
                color: $color-primary-text;
              }

              &.status--signed {
                background: $color-primary;
                color: $color-primary-text;
              }

              &.status--expired {
              }

              &.status--canceled {
              }

              &.status--declined {
                background: $app-color-danger;
                color: $color-primary-text;
              }

              &.status--not-signed {
                background: $color-gray-300;
                color: $color-gray-700;
              }
            }
          }

          .app-docs-document-esign--singer-name {
            margin-top: 10px;
          }

          .attr--horizon-group-input.direction--horizontal > * {
            flex-basis: 51%;

            &:last-child {
              flex-basis: 49%;
            }
          }

          .attr--selector-group.direction--horizontal {
            align-items: flex-end;

            > * {
              flex-basis: auto;
            }

            .cr-checkbox {
              display: flex;
              align-items: center;
              height: 32px;

              .ms-Checkbox-text {
                white-space: nowrap;
              }
            }

            .cr-dropdown .ms-Dropdown-title {
              font-size: 12px;
              text-align: center;
            }

            .cr-input,
            .cr-dropdown {
              flex-basis: 100%;
            }

            button {
              flex-shrink: 0;
              min-width: 32px;
              max-width: 32px;
            }

            .cr-input-group {
              > * {
                flex-basis: auto;
              }

              .cr-input {
                flex-basis: 100%;
              }
            }

            .cr-input.state--invalid {
              .ms-TextField-fieldGroup {
                border-color: $app-color-danger;
              }
            }

            .cr-dropdown.state--invalid {
              .ms-Dropdown-title {
                border-color: $app-color-danger;
              }
            }

            button {
              display: flex;
              align-items: center;
              justify-content: center;
              height: 32px;
              padding: 0;
              border: 1px solid #ccc;
              background: transparent;
              color: #000;
              cursor: pointer;

              i {
                transition: all ease-in-out 0.1s;
              }

              &:hover {
                border-color: #aaa;
                background: #f8f8f8;
              }

              &:active {
                border-color: #999;
                background: #f0f0f0;
              }

              &.state--invalid {
                border-color: $app-color-danger;
                background: $app-color-danger;
                color: $color-primary-text;

                i {
                  color: $color-primary-text;
                }
              }

              &.state--linked {
                border-color: $app-color-warning;
                background: transparent;
                color: $app-color-warning;

                i {
                  color: $app-color-warning;
                  font-weight: bold;
                  transform: rotate(45deg);
                }
              }

              &.state--active {
                border-color: $color-primary;
                background: $color-primary;
                color: $color-primary-text;

                i {
                  color: $color-primary-text;
                }
              }

              &.state--disabled {
                opacity: 0.5;
                cursor: not-allowed;
              }
            }
          }

          .app-docs-document-esign--singer-attrs {
            .app-docs-document-esign--singer-attr-plan {
              > * {
                flex-basis: auto;
              }

              a {
                text-decoration: none;
              }
            }
          }

          .attr--selector-marker {
            label {
              display: block;
              overflow: hidden;
              max-width: 32px;
              color: rgb(164, 38, 44);
              font-size: $font-size-base;
              line-height: 23px;
              text-align: center;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
        }
      }

      .app-docs-document-esign--histories {
        margin: 0;
        padding: 0;
        list-style: none;

        li {
          margin: 10px 0 0 0;
          word-break: keep-all;

          &:first-child {
            margin-top: 0;
          }

          i {
            display: inline-block;
            width: 5px;
            height: 5px;
            margin-right: 8px;
            margin-bottom: 2px;
            background: $color-gray-500;
          }

          &.type--delivered i {
            background: $color-gray-300;
          }

          &.type--canceled i {
            background: $app-color-warning;
          }

          &.type--declined i {
            background: $app-color-danger;
          }

          &.type--opened i {
            background: $app-color-success;
          }

          &.type--signed i {
            background: $color-primary;
          }

          &.type--sign-start i,
          &.type--sign-end i,
          &.type--sign-decline i,
          &.type--sign-cancel i {
            background: $color-primary;
          }

          &.type--sign-expired i {
            background: $app-color-danger;
          }

          &.type--sign-start,
          &.type--sign-end,
          &.type--sign-decline,
          &.type--sign-cancel,
          &.type--sign-expired,
          &.type--signed {
            font-weight: bold;
          }

          span {
            text-decoration: underline;
            word-break: break-all;

            strong {
              word-break: break-all;
            }
          }

          em {
            margin-left: 5px;
            color: $color-gray-600;
            font-style: normal;
            font-size: $font-size-sm;
          }

          p {
            margin: 0;
            color: $app-color-active;
            font-size: $font-size-xs;
          }

          .attr--reason {
            font-weight: normal;
            font-style: italic;
            text-decoration: none;
            word-break: break-all;
          }
        }
      }
    }
  }
}

.app-docs-document-esign--signature-dialog {
  .app-docs-document-esign--signature-canvas-container {
    position: relative;
    margin: auto;

    &:before {
      content: "(SIGN HERE)";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      background: #eee;
      color: #aaa;
      line-height: 150px;
      letter-spacing: 5px;
      text-align: center;
      text-indent: 0;
    }

    .app-docs-document-esign--signature-canvas-clear {
      position: absolute;
      top: 0;
      right: 0;
      z-index: 2;

      button {
        position: relative;
        z-index: 2;
        margin: 0;
        border: 1px solid #333;
        background: #eee;
        color: #000;
        outline: none;
        cursor: pointer;
        user-select: none;

        &:hover {
          background: #e0e0e0;
        }
      }
    }

    .app-docs-document-esign--signature-canvas {
      position: relative;
      width: 100%;
      height: 150px;
    }
  }
}

.app-docs-document-esign-dialog--alert {
  p {
    word-break: keep-all;
  }
}

.app-docs-document-esign-dialog {
  ul {
    margin: 0;
    padding: 0;
    list-style: none;

    li {
      position: relative;
      margin: 0 0 5px 0;
      padding: 0 0 0 16px;
      word-break: keep-all;

      &:before {
        content: "";
        position: absolute;
        top: 8px;
        left: 4px;
        width: 4px;
        height: 4px;
        border-radius: 2px;
        background: #333;
      }
    }
  }
}

.app-docs-document-view-container.mode--viewer {
  .app-docs-document-esign {
    max-width: 0;
    padding-left: 0;
  }
}

.rpv-core__popover-body {
  top: auto !important;
  bottom: 54px !important;

  .rpv-core__arrow {
    top: auto;
    bottom: -6px;
    transform: rotate(45deg);
  }
}

.rpv-core__tooltip-body {
  top: auto !important;
  bottom: 54px !important;

  .rpv-core__arrow {
    top: auto;
    bottom: -4px;
    margin-left: -3px;
    transform: rotate(45deg);
  }
}

#rpv-core__popover-body-inner-zoom {
  .rpv-core__menu {
    button:nth-child(2) {
      display: none;
    }
  }
}

@media print {
  .app-docs-document-view,
  .app-docs-proposal-view {
    &:after {
      content: "PRINT FUNCTIONS ARE RESTRICTED";
      display: block;
      width: 100%;
      height: 500px;
      background: red;
      color: #fff;
      font-size: 1.4rem;
      line-height: 500px;
      text-align: center;
    }
  }

  .app-docs-document-view-inner,
  .app-docs-proposal-pages {
    display: none;
  }
}

.app-docs-proposal-view {
  div,
  section,
  span,
  p {
    font-family: "Noto Sans KR";
  }

  > .cr-section-inner {
    padding: 0;
    background: transparent;
    box-shadow: none;
  }
}

.app-docs-proposal-mode-selector {
  button {
    position: relative;
    margin: 0 0 0 -1px;
    padding: 3px 7px;
    border: 1px solid #999999;
    background: $app-content-box-background;
    cursor: pointer;
    transition: all ease-in-out 0.2s;

    &:hover {
      z-index: 11111;
      box-shadow: 0 0 10px #ccc;
    }

    &:active {
      border-color: #333;
      background: $app-content-background-lighten;
    }

    &.state--selected {
      border-color: $color-primary;
      background: $color-primary;
      color: $color-primary-text;
    }

    &:first-child {
      margin-left: 0;
      padding-left: 10px;
      border-top-left-radius: 15px;
      border-bottom-left-radius: 15px;
    }

    &:last-child {
      padding-right: 10px;
      border-top-right-radius: 15px;
      border-bottom-right-radius: 15px;
    }
  }
}

.app-docs-proposal-composer {
  $-spacing: 0.5rem;
  $-width-portrait: 595px;
  $-width-landscape: 842px;
  $-width-helper: 270px;
  $-width-navigation: 270px;

  // A4	72 PPI : 595 x 842 / 96 PPI : 794 x 1123 / 150 PPI : 1240 x 1754 / 300 PPI : 2480 x 3508

  display: flex;
  margin: calc(#{$-spacing} * -1);
  margin-top: calc(#{$-spacing} * -2);

  @media (max-width: 700px) {
    .app-docs-proposal-navigation {
      display: none;
    }
  }

  /* Scrollbar Styling */
  .app-docs-proposal-navigation,
  .app-docs-proposal-pages,
  .app-docs-proposal-helper {
    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
    }

    &::-webkit-scrollbar-track {
      background-color: #e4e4e4;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #999;
    }
  }

  .app-docs-proposal-navigation,
  .app-docs-proposal-helper {
    margin: $-spacing;
  }

  .app-docs-proposal-navigation,
  .app-docs-proposal-pages section > div,
  .app-docs-proposal-helper section {
    padding: calc(#{$app-content-box-padding} * 1.2);
    background-color: $app-content-box-background;
  }

  .app-docs-proposal-navigation,
  .app-docs-proposal-pages section > div,
  .app-docs-proposal-helper {
    box-shadow: $app-content-box-shadow;
  }

  .app-docs-proposal-navigation,
  .app-docs-proposal-helper {
    position: sticky;
    top: calc(#{$app-content-padding} + #{$app-header-height});
    flex-shrink: 0;
    overflow: auto;
    max-height: calc(100vh - #{$app-content-padding} - #{$app-header-height} - #{$app-footer-bar-height} - 0.35rem);
  }

  .app-docs-proposal-navigation {
    width: $-width-navigation;
  }

  .app-docs-proposal-component {
    position: relative;

    &.type--heading {
      z-index: 1;
      display: flex;

      strong {
        flex-shrink: 0;
        margin-right: 1rem;
        line-height: 100%;
        cursor: default;
      }

      .mdx-cr-slate {
        flex-basis: 100%;

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
          line-height: 100%;
        }
      }

      &.level--1 strong {
        font-size: 1.2em;
      }

      &.level--2 strong {
        font-size: 1.1em;
      }
    }

    &.status--ready,
    &.status--processing,
    &.status--done,
    &.status--error {
      @keyframes blink {
        30% {
          opacity: 1;
        }

        80% {
          opacity: 0;
        }
      }

      &:before {
        $-size: 10px;

        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: -21px;
        width: 10px;
        transition: all ease-in-out 0.15s;
      }

      &.status--ready:before {
        background: yellow;
      }

      &.status--error:before {
        background: red;
      }

      &.status--processing:before {
        background: $color-primary;
        animation: blink 1.1s linear infinite;
      }

      &.status--done:before {
        width: 1px;
        background: $color-primary;
      }
    }
  }

  .app-docs-proposal-navigation {
    ul {
      margin: 0;
      padding: 0;
      list-style: none;

      ul {
        margin-bottom: 0.5rem;
        margin-left: 1rem;

        &:last-child {
          margin-bottom: 0;
        }
      }

      li {
        position: relative;

        &.--indent-0 {
          > a {
            font-weight: 600;
          }
        }

        .--misc {
          position: absolute;
          top: -1px;
          right: -5px;
          bottom: 0;
          display: flex;
          padding-left: 40px;
          opacity: 0;

          button {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 25px;
            height: 100%;
            margin: 0;
            margin-left: -1px;
            border: 0;
            outline: none;
            cursor: pointer;
            appearance: none;

            .cr-icon {
              position: absolute;
              top: 0;
              right: 0;
              bottom: 0;
              left: 0;
              display: flex;
              align-items: center;
              justify-content: center;
              border: 1px solid #ccc;
              background-color: #f7f7f7;
            }

            &:hover {
              z-index: 1;

              .cr-icon {
                z-index: 1;
                border: 1px solid #888;
                border-top-color: #f7f7f7;
              }

              &:after {
                content: "";
                position: absolute;
                top: 0;
                right: 0;
                z-index: 2;
                width: 1px;
                height: 1px;
                background: #888;
              }

              &:before {
                content: attr(data-attr-label);
                position: absolute;
                top: -20px;
                right: 0;
                z-index: 0;
                height: 15px;
                padding: 2px 6px;
                border: 1px solid #888;
                border-radius: 5px;
                border-bottom-right-radius: 0;
                background: #f0f0f0;
                font-size: $font-size-xs;
                white-space: nowrap;
              }
            }

            &:active {
              .cr-icon {
                background-color: #f0f0f0;
              }
            }
          }
        }

        .--misc {
          &:hover {
            display: flex;
            opacity: 1;
          }
        }
      }

      .navi--order {
        display: inline-block;
        min-width: 50px;
        margin-right: 10px;
      }

      .--indent-0 .navi--order {
        min-width: 13px;
        /* 7.8 */
      }

      .--indent-1 .navi--order {
        min-width: 23px;
        /* 18.74 */
      }

      .--indent-2 .navi--order {
        min-width: 40px;
        /* 29.61 */
      }

      .--indent-3 .navi--order {
        min-width: 55px;
      }

      .--indent-4 .navi--order {
        min-width: 70px;
      }

      a {
        display: block;
        overflow: hidden;
        padding: 4px 0 5px 0;
        color: $app-text-color;
        line-height: 100%;
        text-decoration: none;
        text-overflow: ellipsis;
        white-space: nowrap;

        &:before {
          content: "";
          position: absolute;
          top: -1px;
          right: -5px;
          bottom: 0;
          left: -5px;
          display: none;
          border: 1px solid fade-out($app-text-color, 0.6);
        }

        &:hover {
          cursor: pointer;

          &:before {
            display: block;
          }
        }

        &.on {
          &:before {
            display: block;
            border-color: $app-color-active;
            background-color: rgba($app-color-active, 0.08);
          }
        }
      }
    }
  }

  .app-docs-proposal-pages section {
    padding: $-spacing;

    > div {
      width: $-width-portrait;
      min-width: $-width-portrait;
      max-width: $-width-portrait;
      margin-right: auto !important;
      margin-left: auto !important;
      font-size: 14px;

      h1,
      h2,
      h3,
      h4,
      h5,
      h6,
      p,
      div,
      textarea,
      input {
        margin: 0;
        padding: 0;
        font-size: 1em;
      }
    }

    .align--center {
      text-align: center;
    }

    .align--right {
      text-align: right;
    }

    .app-docs-proposal-page-contents {
      padding-bottom: 1rem;

      article {
        position: relative;

        .app-docs-proposal-page-content-tooltip-btn {
          $-btn-size: 30px;

          position: absolute;
          top: calc(#{$-btn-size} * -0.5);
          left: calc(#{$-btn-size} * -1.3);
          z-index: 100;
          width: $-btn-size;
          height: $-btn-size;
          border: none;
          background: transparent;
          text-align: center;
          text-indent: -9999px;
          cursor: pointer;

          &:after {
            content: "question_mark";
            position: absolute;
            top: 0;
            left: 0;
            width: $-btn-size;
            height: $-btn-size;
            border: 1px solid #e0e0e0;
            border-radius: 50%;
            background: #fff;
            box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
            font-size: $font-size-base;
            font-family: "Material Icons Outlined";
            line-height: $-btn-size;
            text-indent: 0;
            transition: all ease-in-out 0.15s;
          }

          &:hover {
            &:after {
              box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
            }
          }

          &.status--enabled {
            cursor: default;

            &:after {
              background: $app-color-warning;
              color: #fff;
            }
          }
        }

        &.app-docs-proposal-page-content-reference {
          padding-top: 0.7rem;

          .reference--item {
            position: relative;
            margin-bottom: 7px;
            margin-left: 30px;

            &:last-child {
              margin-bottom: 0;
            }

            &:before {
              content: attr(data-attr-index) ")";
              position: absolute;
              top: 2px;
              left: -30px;
              font-size: $font-size-base;
              line-height: $font-size-base;
            }
          }

          .reference--empty {
            color: #999;
            font-style: italic;
            cursor: not-allowed;
          }
        }
      }
    }

    .app-docs-proposal-page-content {
      margin-bottom: 1rem;

      &:last-child {
        margin-bottom: 0;

        .app-docs-proposal-page-contents {
          padding-bottom: 0;
        }
      }
    }

    &.section--cover > div {
      min-height: auto;
    }

    .app-docs-proposal-page-cover {
      min-height: calc(#{$-width-portrait} * 0.5);

      &.style--plain {
        div[data-slate-editor] {
          h1 {
            margin-top: 2rem;
            padding: 1.5rem 0;
            border-top: 4px double #333;
            border-bottom: 4px double #333;
            font-size: 1.6em;
          }

          h2 {
            margin-top: 3rem;
            margin-bottom: 1.5rem;
            font-size: 1.1em;
            line-height: 140%;
            word-break: keep-all;
          }

          h3 {
            margin-bottom: 1.5rem;
            font-weight: normal;
            font-size: 1em;
            line-height: 140%;
            word-break: keep-all;
          }
        }
      }

      &.style--h1-mt-1 div[data-slate-editor] h1 {
        margin-top: calc(0.8rem * 1);
      }

      &.style--h1-mt-2 div[data-slate-editor] h1 {
        margin-top: calc(0.8rem * 2);
      }

      &.style--h1-mt-3 div[data-slate-editor] h1 {
        margin-top: calc(0.8rem * 3);
      }

      &.style--h1-mt-4 div[data-slate-editor] h1 {
        margin-top: calc(0.8rem * 4);
      }

      &.style--h1-mt-5 div[data-slate-editor] h1 {
        margin-top: calc(0.8rem * 5);
      }

      &.style--h1-mt-6 div[data-slate-editor] h1 {
        margin-top: calc(0.8rem * 6);
      }

      &.style--h1-mt-7 div[data-slate-editor] h1 {
        margin-top: calc(0.8rem * 7);
      }

      &.style--h1-mb-1 div[data-slate-editor] h1 {
        margin-bottom: calc(0.8rem * 1);
      }

      &.style--h1-mb-2 div[data-slate-editor] h1 {
        margin-bottom: calc(0.8rem * 2);
      }

      &.style--h1-mb-3 div[data-slate-editor] h1 {
        margin-bottom: calc(0.8rem * 3);
      }

      &.style--h1-mb-4 div[data-slate-editor] h1 {
        margin-bottom: calc(0.8rem * 4);
      }

      &.style--h1-mb-5 div[data-slate-editor] h1 {
        margin-bottom: calc(0.8rem * 5);
      }

      &.style--h1-mb-6 div[data-slate-editor] h1 {
        margin-bottom: calc(0.8rem * 6);
      }

      &.style--h1-mb-7 div[data-slate-editor] h1 {
        margin-bottom: calc(0.8rem * 7);
      }
    }

    .app-docs-proposal-page-toc {
      .app-docs-proposal-navigation {
        position: static;
        top: auto;
        overflow: visible;
        width: auto;
        max-height: none;
        padding: 1.5rem;
        box-shadow: none;

        ul li a {
          display: flex;
          overflow: visible;
          text-overflow: unset;
          white-space: normal;

          span {
            flex-shrink: 0;
          }
        }
      }
    }

    .app-docs-proposal-page-content-rich-text-footnote {
      margin: 10px 0 0 0;
      padding: 0 0 0 20px;
      list-style: none;

      li {
        position: relative;

        &:before {
          content: attr(data-attr-index) ")";
          position: absolute;
          top: 0;
          left: -20px;
          font-size: $font-size-base;
        }
      }
    }

    &.section--empty > div {
      min-height: auto;
    }

    &.orientation--landscape > div {
      width: $-width-landscape;
      min-width: $-width-landscape;
      max-width: $-width-landscape;
    }
  }

  .app-docs-proposal-pages {
    flex-basis: 100%;

    .app-docs-proposal-pages {
      position: relative;
    }

    .app-docs-proposal-header {
      margin-bottom: $-spacing;
    }

    .app-docs-proposal-footer {
      margin-top: $-spacing;

      + .app-docs-proposal-page-content {
        $-spacing: 1.5rem;
        $-height: 1rem;
        position: relative;

        margin-top: calc((#{$-spacing} * 2) + #{$-height});

        &:before {
          content: "";
          position: absolute;
          top: calc((#{$-spacing} * -1) - #{$-height});
          right: -2rem;
          left: -2rem;
          height: $-height;
          background: $app-content-background;
        }
      }
    }

    .app-docs-proposal-header,
    .app-docs-proposal-footer {
      position: relative;
      width: 100%;

      &:before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        border: 1px solid #f7f7f7;
      }

      .app-slate-elem-image {
        text-align: inherit;

        > span[data-slate-node] {
          width: 0;
          height: 0;
        }

        img {
          width: auto;
          max-width: none;
          max-height: 40px;
        }
      }
    }

    .app-docs-proposal-sections {
      padding-bottom: $-spacing;
    }
  }

  .app-docs-proposal-helper {
    overflow-x: hidden;
    overflow-y: auto;
    width: 0;
    opacity: 0;
    transition: all ease-in-out 0.4s;

    section {
      width: 100%;
      min-width: $-width-helper;
      min-height: 100%;
    }

    h1 {
      position: sticky;
      top: 0;
      z-index: 2;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: -1.31rem -15px 1.5rem -15px;
      padding: 1.31rem 15px 1rem 15px;
      border-bottom: 1px solid #eee;
      background: #fff;
      user-select: none;

      span {
        display: flex;
        align-items: center;
        margin-top: -3px;
        font-size: $font-size-2xl;
        line-height: 100%;

        i {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 22px;
          height: 22px;
          margin-top: 3px;
          margin-right: 5px;
          border-radius: 50%;
          background: #333;
          color: #fff;
          font-size: $font-size-base;
        }
      }

      button {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 30px;
        height: 30px;
        border: none;
        border-radius: 50%;
        background: transparent;
        font-size: $font-size-xl;
        cursor: pointer;
        transition: all ease-in-out 0.15s;

        &:hover {
          background: #f3f3f3;
          color: #333;
        }
      }
    }

    .mdx-cr-slate {
      &:hover {
        animation: none;
      }
    }

    &.status--enabled {
      width: calc(#{$-width-helper});
      opacity: 1;
    }
  }
}

.mv-callout--docs-signer-date-type {
  .ms-Button-flexContainer {
    justify-content: center;
  }

  .ms-Dropdown-optionText {
    font-size: 12px;
    text-align: center;
  }
}

.mv-callout--docs-sign-reason,
.mv-callout-subject-viewer-icf,
.mv-callout-econsent-file--selelctor {
  .ms-Dropdown-optionText {
    padding: 2px 0;
    white-space: normal;
  }
}

.mv-panel--docs-sign-reason,
.mv-panel-subject-viewer-icf,
.mv-panel-econsent-file--selelctor {
  .ms-Dropdown-item {
    display: inline-block;
    height: auto;

    .ms-Dropdown-optionText {
      padding: 4px 0;
      white-space: normal;
    }
  }
}

.app-project-reference-dialog {
  .ms-Dialog-main {
    .ms-Dialog-content {
      .ms-Button {
        width: 40px;
        min-width: auto;
        padding: 0;
        border-radius: 0;
      }
    }
  }
}

.app-project-footnote-dialog {
  .ms-Dialog-main {
    width: 100%;
    max-width: 690px;

    .ms-Dialog-content {
      min-height: 200px;
    }
  }
}

.app-project-insert-image-dialog {
  .ms-Dialog-main {
    width: 100%;
    max-width: 250px;

    .ms-Dialog-content {
    }
  }
}

.app-project-revision-table-dialog {
  .ms-Dialog-main {
    width: 100%;
    max-width: 800px;

    .ms-Modal-scrollableContent {
      min-width: 500px;
    }

    .app-project-revision-table--input-group {
      > * {
        flex-basis: 50%;
        max-width: 140px;

        &:first-child {
          flex-basis: 100%;
          max-width: none;
        }

        &:last-child {
          flex-shrink: 0;
          max-width: 110px;
        }
      }
    }

    .revision--empty {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 80px;
    }

    .revision--table {
      table {
        overflow: hidden;
        width: 100%;
        border-collapse: collapse;

        td {
          vertical-align: top;
          border: 1px solid #ccc;
          cursor: pointer;

          &:first-child {
            padding: 5px;
          }

          .mdx-cr-slate:hover {
            animation: none !important;
          }

          .attr--diff {
            display: flex;

            > * {
              flex-basis: 50%;
              margin-top: -1px;
              margin-bottom: -1px;
              padding: 10px;

              &:first-child {
                margin-right: 0.5px;
                border-right: 1px solid #ccc;
              }

              &:last-child {
                border-left: 1px solid #ccc;
              }
            }
          }
        }

        .type--modified td .attr--diff > div {
          background: fade-out($app-color-warning, 0.9);
        }

        .type--removed td .attr--diff > div:first-child {
          background: fade-out($app-color-danger, 0.9);
        }

        .type--added td .attr--diff > div:last-child {
          background: fade-out($app-color-success, 0.9);
        }

        .type--modified.state--checked {
          background: fade-out($app-color-warning, 0.9);
        }

        .type--removed.state--checked {
          background: fade-out($app-color-danger, 0.9);
        }

        .type--added.state--checked {
          background: fade-out($app-color-success, 0.9);
        }
      }
    }
  }
}

.app-project-library-dialog {
  .ms-Dialog-main {
    width: 100%;
    max-width: 600px;

    .ms-Dialog-content {
      .library--search {
        $-max-height: 400px;

        overflow: auto;
        max-height: $-max-height;
        border-top: 1px solid #efefef;
        border-bottom: 1px solid #efefef;

        @media (max-height: calc(#{$-max-height} + 220px)) {
          max-height: none;
        }
      }

      .library--empty {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 80px;
      }

      .library--content {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #efefef;

        &:last-child {
          border-bottom: none;
        }

        > *:last-child {
          display: flex;
          flex-shrink: 0;
          align-items: center;
          justify-content: center;
          width: 50px;
          padding: 10px;
          cursor: pointer;

          &:hover {
            background: #f6f6f6;
          }
        }

        > div {
          display: flex;
          flex-basis: 100%;
          flex-direction: column;
          padding: 0.8rem;
          cursor: pointer;

          &:hover {
            background: #f6f6f6;
          }

          p {
            display: -webkit-box;
            overflow: hidden;
            max-height: calc(1.2rem * 3);
            margin: 0;
            padding: 0;
            font-size: $font-size-base;
            line-height: 1.2rem;
            text-overflow: ellipsis;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3;
          }

          em {
            font-weight: bold;
            font-style: normal;
            font-size: $font-size-xs;
          }

          > * {
            margin-bottom: 0.5rem;
          }

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}

.app-project-library-preview-dialog {
  .ms-Dialog-main {
    width: 100%;
    max-width: 600px;
  }
}

.app-project-variables-dialog {
  .ms-Dialog-main {
    width: 100%;
    max-width: 600px;

    .ms-Dialog-content {
      overflow: auto;
      max-height: 400px;

      table {
        width: 100%;
        border-collapse: collapse;
        table-layout: fixed;

        th,
        td {
          margin: 0;
          padding: 0.5rem 0.2rem;

          &:first-child {
            padding-left: 0;
          }

          &:last-child {
            padding-right: 0;
          }
        }

        thead {
          position: sticky;
          top: 0;
          z-index: 1;
        }

        thead th {
          position: relative;
          background: #fff;

          &:after {
            content: "";
            position: absolute;
            right: 0;
            bottom: 0;
            left: 0;
            height: 1px;
            background: #ddd;
          }
        }

        tbody td {
          border-bottom: 1px solid #efefef;

          &.status--empty {
            font-weight: bold;
            text-align: center;
          }
        }

        td,
        input {
          font-family: "Nunito";
        }
      }
    }
  }
}

.mdx-cr-slate {
  $-vertical-spacing: 0.3rem;
  $-vertical-line-height: 145%;

  position: relative;

  &:hover {
    @keyframes hoverBg {
      from {
        background: #f9f7f5;
      }

      to {
        background: transparent;
      }
    }

    animation: hoverBg 0.85s;
  }

  &.state--clickable {
    cursor: pointer;

    &:hover {
      &:before {
        content: "";
        position: absolute;
        top: -7px;
        right: -7px;
        bottom: -7px;
        left: -7px;
        background: #eee;
      }

      &:after {
        content: "";
        position: absolute;
        top: -7px;
        right: -7px;
        bottom: -7px;
        left: -7px;
        z-index: 1;
        border: 1px solid #999;
      }
    }

    * {
      cursor: pointer !important;
    }
  }

  &.state--busy .mdx-cr-slate--indicator i {
    opacity: 1 !important;
  }

  &.attr--toolbar {
  }

  &.attr--toolbar-always-on {
  }

  .mdx-cr-slate--indicator {
    position: sticky;
    top: 85px;
    z-index: 999;
    width: 100%;
    height: 0;
    text-align: right;
    opacity: 0.8;

    i {
      @keyframes rotateOpacity {
        0% {
          transform: rotate(0deg);
        }

        100% {
          transform: rotate(1turn);
        }
      }

      position: absolute;
      top: 0;
      right: -60px;
      display: inline-block;
      width: 25px;
      height: 25px;
      border: 5px solid #ccc;
      border-collapse: collapse;
      border-radius: 50%;
      opacity: 0;
      pointer-events: none;
      transition: opacity ease-in-out 0.2s;

      &:after,
      &:before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        box-sizing: border-box;
        width: 25px;
        height: 25px;
        margin-top: -12.5px;
        margin-left: -12.5px;
        border: 5px solid #259ac5;
        border-right-color: transparent;
        border-bottom-color: transparent;
        border-radius: 50%;
        animation: rotateOpacity 0.95s ease-in-out infinite 0.1s;
        stroke-linecap: round;
      }

      &:after {
        border-color: #36393f;
        border-right-color: transparent;
        border-bottom-color: transparent;
        animation-delay: 0.25s;
      }
    }
  }

  div[data-slate-node] {
    span {
      &.font--huger {
        font-size: 1.5em;
      }

      &.font--huge {
        font-size: 1.4em;
      }

      &.font--largest {
        font-size: 1.25em;
      }

      &.font--larger {
        font-size: 1.1em;
      }

      &.font--large {
        font-size: 1.05em;
      }

      &.font--small {
        font-size: 0.9em;
      }

      &.font--smaller {
        font-size: 0.8em;
      }

      &.font--tiny {
        font-size: 0.7em;
      }

      &.color--red {
        color: red;
      }

      &.color--orange {
        color: orange;
      }

      &.color--yellow {
        color: yellow;
      }

      &.color--green {
        color: green;
      }

      &.color--blue {
        color: blue;
      }

      &.blame--deleted {
        display: none;
      }
    }

    h1 {
      font-size: 1.2em;

      &:not(:first-child) {
        margin-top: 1.3rem;
      }
    }

    h2 {
      font-size: 1.1em;

      &:not(:first-child) {
        margin-top: 1.1rem;
      }
    }

    h3 {
      &:not(:first-child) {
        margin-top: 0.9rem;
      }
    }

    h4 {
      &:not(:first-child) {
        margin-top: 0.7rem;
      }
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin-bottom: $-vertical-spacing;
    }

    div,
    table,
    p,
    .app-slate-elem-list {
      margin: $-vertical-spacing 0;
      line-height: $-vertical-line-height;
    }

    div {
      line-height: $-vertical-line-height;
    }

    table {
      border-collapse: collapse;
      table-layout: fixed;

      &.position--bottom {
        margin-top: 7rem;
      }

      tr {
        td {
          padding: 0 0.3rem;
          border: 1px solid #333;

          p {
            word-break: keep-all;
          }
        }
      }

      .app-slate-elem-table-colgroup {
        td {
          position: relative;
          height: 0;
          padding: 0;
          border: none;
        }
      }
    }

    .app-slate-elem-image {
      text-align: center;

      img {
        max-width: 100%;
        height: auto;
        max-height: 100%;
        border-radius: 0;
        outline: none;
        pointer-events: none;
      }

      &.state--selected {
        img {
          border-radius: 3px;
          box-shadow: 0 0 0 5px lighten($app-color-warning, 25%);
        }
      }
    }

    .app-slate-elem-paragraph {
      margin: $-vertical-spacing 0;
      line-height: initial !important;

      &.indent--1 {
        margin-left: calc(28px * 1);
      }

      &.indent--2 {
        margin-left: calc(28px * 2);
      }

      &.indent--3 {
        margin-left: calc(28px * 3);
      }

      &.indent--4 {
        margin-left: calc(28px * 4);
      }

      &.indent--5 {
        margin-left: calc(28px * 5);
      }

      span[data-slate-zero-width="z"][data-slate-length="0"] {
        display: inline-block;
        width: 0;
      }
    }

    .app-slate-elem-mirroring {
      position: relative;
      background: repeating-linear-gradient(45deg, #f9f9f9, #f9f9f9 5px, #fefefe 5px, #fefefe 10px);
      cursor: grab;

      &.state--focused {
        &:after {
          $-weight: 4px;

          content: "";
          position: absolute;
          top: calc(#{$-weight} * -1);
          right: calc(#{$-weight} * -1);
          bottom: calc(#{$-weight} * -1);
          left: calc(#{$-weight} * -1);
          border: $-weight solid #eee;
          cursor: grab;
        }
      }

      .app-slate-elem-mirroring-curtain {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        background: rgba(255, 255, 255, 0.05);
        color: rgba(0, 0, 0, 0);
        transition: all ease-in-out 0.1s;

        &.state--breaking {
          background: rgba(255, 255, 255, 0.7);
          color: rgba(0, 0, 0, 1);
          text-shadow: 1px 1px 0 #fff;
        }

        &.state--breaked {
          display: none;
        }
      }

      &.state--readonly {
        background: transparent;
        cursor: default;

        .app-slate-elem-mirroring-curtain {
          display: none;
        }
      }
    }

    .app-slate-elem-list {
      $-list-indent: 28px;

      position: relative;
      margin: $-vertical-spacing 0 $-vertical-spacing $-list-indent;
      padding: 0;
      list-style: none;

      &.indent--1 {
        margin-left: calc(#{$-list-indent} * 2);
      }

      &.indent--2 {
        margin-left: calc(#{$-list-indent} * 3);
      }

      &.indent--3 {
        margin-left: calc(#{$-list-indent} * 4);
      }

      &.indent--4 {
        margin-left: calc(#{$-list-indent} * 5);
      }

      &.indent--5 {
        margin-left: calc(#{$-list-indent} * 6);
      }

      .app-slate-elem-list-item {
        $-list-item-char-size: 7px;

        position: relative;
        margin-left: 0;
        padding-left: calc(#{$-list-item-char-size} * 4);

        &:before {
          content: attr(data-attr-index-num) ".";
          position: absolute;
          top: 0;
          left: calc(#{$-list-indent} * -1);
          display: block;
          width: calc(#{$-list-item-char-size} * 6);
          font-weight: 500;
          text-align: right;
        }

        // NOTE: ul, ol 태그의 기본 기호 삭제
        &::marker {
          content: "";
        }
      }

      .app-slate-elem-list-item.state--bold:before {
        font-weight: bold;
      }

      // NOTE: Unordered List Symbols
      &.style--small-disc > .app-slate-elem-list-item:before {
        content: "·";
        padding-left: 3px;
      }

      &.style--disc > .app-slate-elem-list-item:before {
        content: "•";
        padding-left: 3px;
      }

      &.style--large-disc > .app-slate-elem-list-item:before {
        content: "●";
        padding-left: 3px;
      }

      &.style--circle > .app-slate-elem-list-item:before {
        content: "◦";
        padding-left: 0px;
      }

      &.style--large-circle > .app-slate-elem-list-item:before {
        content: "○";
        padding-left: 3px;
      }

      &.style--small-square > .app-slate-elem-list-item:before {
        content: "▪︎";
        padding-left: 3px;
      }

      &.style--square > .app-slate-elem-list-item:before {
        content: "◼︎";
        padding-left: 3px;
      }

      &.style--diamond > .app-slate-elem-list-item:before {
        content: "◆";
        padding-left: 3px;
      }

      &.style--check-mark > .app-slate-elem-list-item:before {
        content: "✔︎";
        padding-left: 3px;
      }

      &.style--four-pointed-star > .app-slate-elem-list-item:before {
        content: "✧";
        padding-left: 3px;
      }

      &.style--right-arrow > .app-slate-elem-list-item:before {
        content: "➜";
        padding-left: 3px;
      }

      &.style--right-arrow-head > .app-slate-elem-list-item:before {
        content: "➢";
        padding-left: 3px;
      }

      &.style--hyphen > .app-slate-elem-list-item:before {
        content: "-";
        padding-left: 3px;
      }

      // NOTE: Ordered List Symbols
      &.style--num-circle > .app-slate-elem-list-item:before {
        content: attr(data-attr-index-circle);
      }

      &.style--num-circle-dot > .app-slate-elem-list-item:before {
        content: attr(data-attr-index-circle) ".";
      }

      &.style--num-dot > .app-slate-elem-list-item:before {
        content: attr(data-attr-index-num) ".";
      }

      &.style--num-bracket-round > .app-slate-elem-list-item:before {
        content: "(" attr(data-attr-index-num) ")";
      }

      &.style--num-bracket-round-single > .app-slate-elem-list-item:before {
        content: attr(data-attr-index-num) ")";
      }

      &.style--num-bracket-square > .app-slate-elem-list-item:before {
        content: "[" attr(data-attr-index-num) "]";
      }

      &.style--num-bracket-angle > .app-slate-elem-list-item:before {
        content: "<" attr(data-attr-index-num) ">";
      }

      &.style--roman-uc-dot > .app-slate-elem-list-item:before {
        content: attr(data-attr-index-roman) ".";
      }

      &.style--roman-uc-bracket-round > .app-slate-elem-list-item:before {
        content: "(" attr(data-attr-index-roman) ")";
      }

      &.style--roman-uc-bracket-round-single > .app-slate-elem-list-item:before {
        content: attr(data-attr-index-roman) ")";
      }

      &.style--roman-uc-bracket-square > .app-slate-elem-list-item:before {
        content: "[" attr(data-attr-index-roman) "]";
      }

      &.style--roman-uc-bracket-angle > .app-slate-elem-list-item:before {
        content: "<" attr(data-attr-index-roman) ">";
      }

      &.style--roman-lc-dot > .app-slate-elem-list-item:before {
        content: attr(data-attr-index-roman) ".";
        text-transform: lowercase;
      }

      &.style--roman-lc-bracket-round > .app-slate-elem-list-item:before {
        content: "(" attr(data-attr-index-roman) ")";
        text-transform: lowercase;
      }

      &.style--roman-lc-bracket-round-single > .app-slate-elem-list-item:before {
        content: attr(data-attr-index-roman) ")";
        text-transform: lowercase;
      }

      &.style--roman-lc-bracket-square > .app-slate-elem-list-item:before {
        content: "[" attr(data-attr-index-roman) "]";
        text-transform: lowercase;
      }

      &.style--roman-lc-bracket-angle > .app-slate-elem-list-item:before {
        content: "<" attr(data-attr-index-roman) ">";
        text-transform: lowercase;
      }
    }

    .app-slate-elem-page-text {
      padding: 0 3px;
      border: 1px dashed #efefef;

      &.state--focused {
        border-color: #ccc;
      }

      &.state--selected {
        border-color: $app-color-warning;
      }
    }

    .app-slate-elem-reference-text {
      position: relative;

      &:hover {
        border-bottom: 3px solid #efefef;
      }

      &:after {
        content: attr(data-attr-index);
        vertical-align: super;
        margin-left: 2px;
        font-size: $font-size-xs;
      }

      &.state--focused {
        border-bottom: 3px solid #e9e9e9;

        &:hover {
          border-color: #e0e0e0;
        }
      }

      &.state--selected {
        background: #c7c7c7;
      }
    }

    .app-slate-elem-footnote-text {
      position: relative;

      &:hover {
        border-bottom: 3px solid #efefef;
      }

      &:after {
        content: attr(data-attr-index) ")";
        vertical-align: super;
        margin-left: 2px;
        font-size: $font-size-xs;
      }

      &.state--focused {
        border-bottom: 3px solid #e9e9e9;

        &:hover {
          border-color: #e0e0e0;
        }
      }

      &.state--selected {
        background: #c7c7c7;
      }
    }

    .app-slate-elem-variable-text {
      position: relative;
      padding: 0 2px;
      border-bottom: 3px solid #f3f3f3;
      background: repeating-linear-gradient(45deg, #f0f0f0, #f0f0f0 5px, #fdfdfd 5px, #fdfdfd 10px);
      cursor: default;
      user-select: none;

      &.state--unbind {
        border-bottom: 3px solid #dddddd;
        background: repeating-linear-gradient(45deg, #f0f0f0, #f0f0f0 5px, #fdfdfd 5px, #fdfdfd 10px);
        word-break: break-all;

        em {
          margin-right: 3px;
          color: $app-color-danger;
          font-weight: bold;
        }
      }

      &.state--orphaned {
        border-bottom: 3px solid #dddddd;
        background: repeating-linear-gradient(45deg, #f0f0f0, #f0f0f0 5px, #fdfdfd 5px, #fdfdfd 10px);
        color: rgba($app-text-color, 0.3);
        word-break: break-all;

        em {
          margin-right: 3px;
          color: rgba($app-color-danger, 0.3);
          font-weight: bold;
        }
      }

      &.state--focused {
        border-bottom: 3px solid #ccc;
        background: repeating-linear-gradient(45deg, #f0f0f0, #f0f0f0 5px, #fdfdfd 5px, #fdfdfd 10px);
      }

      &.state--selected {
        border-color: lighten($color-primary, 20%);
        background: repeating-linear-gradient(45deg, #dfdfdf, #dfdfdf 5px, #fdfdfd 5px, #fdfdfd 10px);
      }
    }
  }

  &.state--tracking-mode {
    div[data-slate-node] {
      span {
        &.blame--inserted {
          background: repeating-linear-gradient(-45deg, #fff, #fff 5px, rgba(blue, 0.3) 5px, rgba(blue, 0.3) 10px);
        }

        &.blame--deleted {
          display: inline;
          background: repeating-linear-gradient(-45deg, #fff, #fff 5px, rgba(red, 0.3) 5px, rgba(red, 0.3) 10px);
        }
      }
    }
  }
}

.mdx-cr-slate--portal {
  position: absolute;
  top: -9999px;
  left: -9999px;
  z-index: 1;
  padding: 4px;
  border-radius: 7px;
  background: #fff;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);

  &.type--variable-text {
  }

  .mdx-child--item {
    padding: 0.3rem 0.9rem 0.3rem 0.7rem;
    border-radius: 5px;
    line-height: 100%;
    cursor: pointer;

    &:hover {
      background: rgba(0, 0, 0, 0.07);
    }

    &.status--selected {
      background: $color-primary;
      color: #fff;
    }
  }
}

$mdx--pdf-viewer-marker-height: 23px;
$mdx--pdf-viewer-marker-indicator-size: 10px;
$mdx--pdf-viewer-marker-padding-left: calc(#{$mdx--pdf-viewer-marker-indicator-size});

.mdx--pdf-viewer,
.mdx--docs-pdf-viewer {
  .rpv-core__viewer {
    > div {
      > div {
        overflow: unset !important;
      }
    }
  }

  .mdx--pdf-viewer-page-watermark {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .mdx--pdf-viewer-marker-zone {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    display: none;
    width: 100%;
    height: 100%;
    text-indent: -9999px;

    &:before {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      border: 3px solid $app-color-warning;
    }

    .mdx--pdf-viewer-marker-zone-marker {
      position: absolute;
      display: flex;
      padding: 0 calc(#{$mdx--pdf-viewer-marker-height} + 5px) 0
        calc(#{$mdx--pdf-viewer-marker-padding-left} + #{$mdx--pdf-viewer-marker-height} / 2.5);
      border-radius: 50rem;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      background: linear-gradient(
        90deg, transparent calc(#{$mdx--pdf-viewer-marker-height} / 2), $color-primary calc(#{$mdx--pdf-viewer-marker-height} / 2)
      );
      color: $color-primary-text;
      font-size: $font-size-sm;
      line-height: $mdx--pdf-viewer-marker-height;
      text-indent: 0;
      white-space: nowrap;
      cursor: default;
      transition: background ease-in-out 0.1s;
      transform: translateY(-50%);

      &.state--readonly {
        padding-right: 10px;
      }

      &:hover {
        background: linear-gradient(
          90deg, transparent calc(#{$mdx--pdf-viewer-marker-height} / 2), rgba(darken($color-primary, 5%), 0.4) calc(#{$mdx--pdf-viewer-marker-height} / 2)
        );
      }

      &:before {
        content: "";
        position: absolute;
        top: 0;
        left: calc(#{$mdx--pdf-viewer-marker-height} / -2);
        width: 0;
        height: 0;
        border: calc(#{$mdx--pdf-viewer-marker-height} / 2) solid $app-color-warning;
        border-top-color: transparent;
        border-bottom-color: transparent;
        border-left-color: transparent;
      }

      span {
        $-size: $mdx--pdf-viewer-marker-height;

        position: absolute;
        top: 0;
        right: 0;
        width: $-size;
        height: $-size;
        border-radius: 50rem;
        background: darken($color-primary, 20%);
        font-family: "Material Icons Outlined";
        line-height: $-size;
        text-align: center;
        cursor: pointer;
      }
    }
  }

  .mdx--pdf-viewer-marker-zone {
    display: block;
  }
}

.app-docs-document-view-container.mode--linking {
  @media (max-width: $break-xl) {
    .app-docs-document-esign {
      right: -310px;

      .app-docs-document-esign-inner {
        > * {
          opacity: 0.5;
        }
      }
    }
  }

  .mdx--pdf-viewer,
  .mdx--docs-pdf-viewer {
    .mdx--pdf-viewer-marker-zone {
      display: block;

      &:before {
        content: "";
      }

      &:hover {
        &:before {
          border-color: darken($app-color-warning, 15%);
          cursor: pointer;
        }
      }
    }

    .mdx--pdf-viewer-marker-zone-marker {
      padding-right: 10px;

      span {
        display: none;
      }
    }
  }
}

.app-docs-document-view-container.mode--signing {
  @media (max-width: $break-xl) {
    .app-docs-document-esign {
      position: relative;
      top: unset;
      right: unset;
      max-width: 100%;
      max-height: unset;
      margin-top: 20px;
      padding-left: 0;

      .app-docs-document-esign-inner {
        width: 100%;
      }
    }
  }

  .mdx--pdf-viewer-marker-zone-marker {
    @keyframes blink_attr {
      35% {
        background: #efefef;
      }

      60% {
        background: #ccc;
      }

      85% {
        background: #efefef;
      }
    }

    @keyframes blink_attr_sign {
      40% {
        opacity: 1;
      }

      60% {
        opacity: 0;
      }

      80% {
        opacity: 1;
      }
    }

    @keyframes pin_attr_sign {
      0% {
        transform: translateX(0%);
      }

      50% {
        transform: translateX(20%);
      }
    }

    @keyframes blink_attr_opacity {
      55% {
        opacity: 1;
      }

      60% {
        opacity: 0.5;
      }

      65% {
        opacity: 1;
      }
    }

    padding: 6px 8px !important;
    border-radius: 0;
    background: #efefef;
    color: #000000;
    font-size: 12px;
    line-height: 100%;
    cursor: progress;
    animation: blink_attr 1.1s linear infinite;

    &:before {
      display: none;
    }

    &:hover {
      background: #e4e4e4 !important;
    }

    &.attr--stamp-api-mark {
      color: transparent;
      opacity: 0.8;
    }

    &.attr--responsive-item-field-mark {
      background: transparent;
      text-indent: -9999px;
      cursor: pointer;
      animation: none;

      &:before {
        content: "";
        position: absolute;
        top: 50%;
        left: 0;
        display: block;
        width: 1px;
        height: 1px;
        border: none;
        background: rgba(yellow, 0.7);
        cursor: pointer;
        transform: scale(20);
        animation: blink_attr_opacity 6s linear infinite;
      }

      &:after {
        $-size: 10px;
        content: "✔";
        position: absolute;
        top: 50%;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        width: calc(#{$-size} * 2);
        height: calc(#{$-size} * 2);
        margin-top: calc(#{$-size} * -1);
        margin-left: calc(#{$-size} * -1);
        font-size: calc(#{$-size} * 2);
        line-height: 100%;
        text-indent: 0;
        opacity: 0;
        transition: all ease-in-out 0.15s;
        transform: scale(1);
      }

      &:hover {
        background: transparent !important;

        &:after {
          background: rgba(yellow, 0.85);
          animation: none;
        }
      }

      &.state--checked {
        &:after {
          opacity: 1;
        }
      }
    }

    &.attr--name {
      padding: 0 4px 0 7px !important;
      font-size: $font-size-sm;
      line-height: 23px;
      letter-spacing: 3px;
    }

    &.attr--date {
      padding-top: 0 !important;
      padding-bottom: 0 !important;
      font-size: $font-size-xs;
      line-height: 23px;
      letter-spacing: -0.5px;
    }

    &.attr--signature {
      background: $app-color-warning;
      color: $color-primary-text;
      cursor: pointer;
      animation: blink_attr_sign 1.1s linear infinite;

      &:hover {
        background: darken($app-color-warning, 5%) !important;
      }

      &:after {
        content: "arrow_back";
        position: absolute;
        top: 50%;
        left: 100%;
        display: block;
        width: 26px;
        height: 26px;
        margin-top: -13px;
        margin-left: -2px;
        border-radius: 50%;
        background: $app-color-warning;
        font-family: "Material Icons Outlined";
        line-height: 26px;
        text-align: center;
        animation: pin_attr_sign 0.6s linear infinite;
      }

      &.state--signed {
        background: transparent;
        text-indent: -9999px;
        animation: none;

        img {
          $-size: 32px;

          position: absolute;
          top: 50%;
          width: auto;
          height: $-size;
          margin-top: calc(#{$-size} / -2);
        }

        &:after {
          display: none;
        }
      }
    }

    &.attr--sign {
      background: $app-color-warning;
      color: $color-primary-text;
      animation: blink_attr_sign 1.1s linear infinite;

      &:hover {
        background: darken($app-color-warning, 5%) !important;
      }
    }
  }
}
